import { getAssetPath } from '@/utils'

import { APP_NAME } from './app'

export const ABOUT_CONTENT_1 = {
  image: getAssetPath('cute-alien.svg'),
  alt: 'Rocket Launch',
  title: 'Who Are We?',
  label: APP_NAME,
  content: [
    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Expedita, ab officia animi libero quaerat sed soluta repudiandae aperiam autem eum facilis deserunt hic maiores architecto voluptatem maxime? Libero, sint provident?',
    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Expedita, ab officia animi libero quaerat sed soluta repudiandae aperiam autem eum facilis deserunt hic maiores architecto voluptatem maxime? Libero, sint provident? Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum dolorem cumque distinctio',
    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Expedita, ab officia animi libero quaerat sed soluta repudiandae aperiam autem eum facilis deserunt hic maiores architecto voluptatem maxime? Libero, sint provident? Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum dolorem cumque distinctio',
  ],
}

export const ABOUT_CONTENT_2 = {
  image: getAssetPath('pink-spaceship.svg'),
  alt: 'Rocket Launch',
  title: 'Who Are We?',
  label: APP_NAME,
  content: [
    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Expedita, ab officia animi libero quaerat sed soluta repudiandae aperiam autem eum facilis deserunt hic maiores architecto voluptatem maxime? Libero, sint provident?',
    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Expedita, ab officia animi libero quaerat sed soluta repudiandae aperiam autem eum facilis deserunt hic maiores architecto voluptatem maxime? Libero, sint provident? Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum dolorem cumque distinctio',
    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Expedita, ab officia animi libero quaerat sed soluta repudiandae aperiam autem eum facilis deserunt hic maiores architecto voluptatem maxime? Libero, sint provident? Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum dolorem cumque distinctio',
  ],
}
