import { env } from '@/configs'

import { TCalculatePrice } from './formatter.types'

export function formatCurrency(v: number | string) {
  if (v === undefined || v === null) return '$0.00'

  const value = typeof v === 'string' ? parseFloat(v) : v

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)
}

export const calculatePrice = ({ price, discount }: TCalculatePrice) => {
  const d = discount ? Number(discount) : 0
  const p = Number(price)

  return formatCurrency(Number((p - p * d).toFixed(2)))
}

export const floatToPercent = (num: number) => Number(num * 100).toFixed(0)

export const handleFetchError = (namespace: string, error: unknown): never => {
  if (error instanceof Error) {
    throw new Error(`${namespace} - ${error.message}`)
  } else {
    throw new Error(`${namespace} - Unknown error`)
  }
}

export const formatNumber = (num: string | number, delimiter = '.') => {
  if (num === undefined || num === null) return '0'

  const formatted = num.toLocaleString()
  return formatted.replace(/,/g, delimiter)
}

export const getShortName = (name: string) => {
  if (!name) return 'HQ'
  const nameParts = name.trim().split(' ')

  const initials = [
    nameParts[0][0].toUpperCase(),
    nameParts[nameParts.length - 1][0].toUpperCase(),
  ]

  return initials.join('')
}

export const getAssetPath = (asset: string) => {
  return `${env.NEXT_PUBLIC_BASE_URL}/static/${asset}`
}

export const getFromCdn = (asset: string) => {
  return `${env.NEXT_PUBLIC_BASE_URL}/cdn/${asset}`
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const slugToTitle = (slug: string): string =>
  slug
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
