export const LANDING_STEP_CONTENT = {
  title: 'How does it work?',
  items: [
    {
      id: 1,
      title: 'Choose Your Service',
      description:
        'Explore our services and select the one that fits your social media growth needs.',
    },

    {
      id: 2,
      title: 'Provide Your Information',
      description:
        'Fill in the required details, such as your username or post link, to ensure accurate delivery.',
    },
    {
      id: 3,
      title: 'Complete Your Order',
      description:
        'Finalize your purchase securely, and our team will deliver your order within the promised timeframe. Sit back, relax, and watch your profile grow!',
    },
  ],
}
