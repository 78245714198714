import { env } from '@/configs'

import { TFetchResponse } from './fetch.types'

const credentials = Buffer.from(
  `koative:ERChopolOmastabatraNcOLowNemoUST`
).toString('base64')

export const $fetch = async <T>(
  url: string,
  options?: RequestInit
): Promise<TFetchResponse<T>> => {
  const response = await fetch(`${env.API_URL}${url}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${credentials}`,
    },
    ...options,
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response.json()
}
