export const TOAST_REMOVE_ITEM_FROM_CART_WITH_ACTION = {
  title: 'Remove this item from your cart?',
}
export const TOAST_REMOVE_ITEM_FROM_CART = {
  title: 'Item removed from your cart',
}
export const TOAST_ADD_ITEM_TO_CART = {
  title: 'Item added to your cart',
  duration: 1500,
}
export const TOAST_ADD_ITEM_TO_CART_ERROR = {
  title: 'Item may already be in your cart.',
}
export const TOAST_LINK_COPIED_TO_CLIPBOARD = {
  title: 'Link copied to clipboard',
}

export const TOAST_SIGN_UP_SUCCESS = {
  title: 'Congratulations!',
  description: 'You’re all set! Welcome aboard. Redirecting you now…',
}

export const TOAST_SIGN_UP_ERROR = {
  title: 'Error',
  description: 'Failed to sign up',
}
export const TOAST_LOGIN_SUCCESS = {
  title: 'Congratulations!',
  description: 'You’re all set! Welcome aboard. Redirecting you now…',
}

export const TOAST_LOGIN_ERROR = {
  title: 'Error',
  description: 'Failed to log in',
}
export const TOAST_LOGOUT_SUCCESS = {
  title: 'You’ve been logged out',
  description: 'We hope to see you again soon!',
}
