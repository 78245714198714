export * from './prisma'
export * from './auth'
export * from './fetch'
export * from './formatter'
export * from './client-helpers'
export * from './server-helpers'

export const offerGenerator = ({
  quantity,
  price,
}: {
  quantity: number
  price: number
}) => {
  return [
    {
      quantity,
      price: price * quantity,
    },
    {
      quantity: quantity * 2,
      price: price * quantity * 2,
    },
    {
      quantity: quantity * 5,
      price: price * quantity * 5,
    },
    {
      quantity: quantity * 10,
      price: price * quantity * 10,
    },
    {
      quantity: quantity * 20,
      price: price * quantity * 20,
    },
    {
      quantity: quantity * 50,
      price: price * quantity * 50,
    },
    {
      quantity: quantity * 100,
      price: price * quantity * 100,
    },
    {
      quantity: quantity * 200,
      price: price * quantity * 200,
    },
  ]
}
export const protectedRoutes = ['/sign-up', '/login', '/payment']

export const isProtectedRoute = (pathname: string) =>
  protectedRoutes.some((route) => pathname.startsWith(route))
