import { capitalizeFirstLetter } from '@/utils'

export const PRODUCT_PROGRESS_SECTION_CONTENTS = {
  title: 'How to Buy Tiktok Followers',
  description: 'Our AI solution will help you from start to finish',
  items: [
    {
      id: 1,
      title: 'Find the Service You Need',
      description:
        'Quickly discover the right tools and experts to boost your social media presence.',
    },
    {
      id: 2,
      title: 'Fill in the Necessary Fields',
      description:
        'Ensure all required fields are completed to get the most out of our services.',
    },
    {
      id: 3,
      title: 'Complete Your Order',
      description:
        'Finalize your order and receive your high-quality followers instantly.',
    },
  ],
}

export const createStepContent = ({
  label,
  name: nameParam,
}: {
  label: string
  name: string
}) => {
  const name = capitalizeFirstLetter(nameParam)
  return {
    title: `How to Buy ${name}`,
    description: `Follow these steps to purchase ${name} easily and securely`,
    items: [
      {
        id: 1,
        title: `Customize Your Order`,
        description: `Choose the quantity and quality of ${name} that best suits your needs. Ensure you select the right options to get the best results.`,
      },
      {
        id: 2,
        title: `Fill ${label}`,
        description: `Provide the link where you want the ${name} to be delivered. Make sure the link is correct to avoid any issues.`,
      },
      {
        id: 3,
        title: `Add to Cart and Pay`,
        description: `Add the selected ${name} items to your cart and proceed to payment to complete your purchase. Enjoy your new ${name}!`,
      },
    ],
  }
}
