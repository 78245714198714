import {
  Flame,
  HeartHandshakeIcon,
  RocketIcon,
  ShieldCheckIcon,
  Star,
} from 'lucide-react'

export const APP_DOMAIN = 'hqbooster.com'

export const APP_NAME = 'HQBooster'

export const APP_EMAIL = 'support@hqbooster.com'

export const APP_PHONE = '+90 555 071 7411'

export const APP_ADDRESS = 'Perpa Trade Center Floor:13/B No: 1368 Istanbul'

export const APP_COMMUNITY_URLS = [
  {
    name: 'WhatsApp',
    url: 'https://chat.whatsapp.com/JYxGMAA0WqxHtPRFn3OeXt',
  },
  {
    name: 'Telegram',
    url: 'https://t.me/hqbooster',
  },
  {
    name: 'Discord',
    url: 'https://discord.gg/PcXCJBkNA9',
  },
]

export const APP_NAV_ITEMS = [
  {
    href: '/support',
    label: 'Support',
  },
  {
    href: '/order-status',
    label: 'Order Status',
  },
  {
    href: '/about',
    label: 'About',
  },
  {
    href: '/blog',
    label: 'Blog',
  },
]

export enum PRODUCT_QUALITY_KEYS {
  STANDARD = 'standard',
  PREMIUM = 'premium',
}
export const PRODUCT_QUALITY_LABELS = {
  [PRODUCT_QUALITY_KEYS.STANDARD]: 'HQ',
  [PRODUCT_QUALITY_KEYS.PREMIUM]: 'PREMIUM',
}
export const PRODUCT_QUALITIES = (name: string) => [
  {
    label: `High Quality ${name}`,
    value: PRODUCT_QUALITY_KEYS.STANDARD,
    Icon: Star,
    iconClassName: 'fill-yellow-500 text-yellow-500 animate-pulse',
  },
  {
    label: `Real Premium ${name}`,
    value: PRODUCT_QUALITY_KEYS.PREMIUM,
    Icon: Flame,
    iconClassName: 'fill-red-600 text-red-600 animate-pulse',
  },
  // {
  //   label: `VIP Influencer ${name}`,
  //   value: 'vip',
  //   Icon: Gem,
  //   iconClassName: 'fill-blue-500 text-blue-200 animate-pulse',
  // },
]

export const PRODUCT_PLATFORMS = [
  {
    name: 'Instagram',
    slug: 'buy-instagram-followers',
    uuid: 'instagram',
  },
  {
    name: 'Tiktok',
    slug: 'buy-tiktok-followers',
    uuid: 'tiktok',
  },
]

export const APP_FEATURES = [
  {
    id: 1,
    title: 'Fast Delivery',
    description:
      'Your order will be processed quickly and efficiently, with most deliveries completed within a short time frame.',
    Icon: RocketIcon,
  },
  {
    id: 2,
    title: '24/7 Support',
    description:
      'Our team is available 24/7 to help you with any questions or concerns.',
    Icon: HeartHandshakeIcon,
  },
  {
    id: 3,
    title: 'High Quality',
    description:
      'We provide the highest quality services on the market. No fake accounts.',
    Icon: Star,
  },
  {
    id: 4,
    title: 'Secure Payment',
    description: 'We use secure payment methods to ensure your data is safe',
    Icon: ShieldCheckIcon,
  },
]

export const APP_STATS = [
  {
    id: 1,
    title: '5.2K+',
    subtitle: 'Happy Users',
    description: 'Guaranteed quality and value in every service.',
  },
  {
    id: 2,
    title: '10x',
    subtitle: 'Better Quality',
    description: 'Unmatched excellence, every time.',
  },
  {
    id: 3,
    title: '25M+',
    subtitle: 'Delivered Units',
    description:
      'Over 25 million followers, likes, comments, and more delivered to empower your growth.',
  },
  {
    id: 4,
    title: '50K+',
    subtitle: 'Orders Completed',
    description: 'Trusted by millions for proven results.',
  },
]

export const APP_QUESTIONS_ANSWERS = [
  {
    id: 1,
    title: 'If I use HQBooster, will I get restricted?',
    description:
      'No, our methods are safe, and your account won’t be restricted.',
  },
  {
    id: 2,
    title: 'How long does it take to deliver the order?',
    description:
      'Orders are delivered within 24 hours. Contact us for any questions!',
  },
  {
    id: 3,
    title: 'Is the purchase of services from HQBooster secure and legal?',
    description:
      'Yes, our services are 100% secure, legal, and comply with platform guidelines.',
  },
  {
    id: 4,
    title: 'What should I do after I place my order?',
    description:
      "Nothing! We'll process your order immediately; this usually takes 30 minutes to 24 hours. In the meantime, sit back, relax, and enjoy yourself!",
  },
  {
    id: 4,
    title: 'How do I place an order on HQBooster?',
    description:
      'Choose the service you need, provide the required details (like your username or post link), and complete the payment. It’s fast and simple!',
  },
]
