import { TMailArgs } from '@/types'

import { APP_EMAIL } from './app'

export const SUPPORT_MESSAGE_TEMPLATE = ({
  firstname,
  lastname,
  email,
  message,
  subject,
}: {
  firstname: string
  lastname: string
  email: string
  message: string
  subject: string
}) => {
  return `📩 New Message
👤 <b>${firstname} ${lastname}</b>
📧 <code>${email}</code>
📅 ${new Date().toLocaleString()}

<b>${subject}</b>

<i>${message}</i>
`
}

export const ORDER_MESSAGE_TEMPLATE = ({
  mail,
  fullName,
  orderId,
  orderDate,
  totalPrice,
}: {
  mail: string
  fullName: string
  orderId: string
  orderDate: string
  totalPrice: string
}) => {
  return `
<b>Order Confirmation</b> 💸

👤 <b>${fullName}</b>
📧 <code>${mail}</code>
📅 <b>${orderDate}</b>
💵 <b>${totalPrice}</b>

🔗 <a href="https://hqbooster.com/order-status/${orderId}">${orderId}</a>
`
}

export const ORDER_MAIL_TEMPLATE = ({
  mail,
  fullName,
  orderId,
  orderDate,
  totalPrice,
}: TMailArgs) => {
  return {
    to: mail,
    from: APP_EMAIL,
    subject: `Order Confirmation - ${orderId}`,
    templateId: 'd-8e7858fbb5c845c4885f1330ee662069',
    dynamicTemplateData: {
      fullName,
      orderId,
      orderDate,
      totalPrice,
    },
  }
}
