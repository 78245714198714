export const env = {
  API_URL: process.env.NEXT_PUBLIC_API_URL as string,
  PAYTR_MERCHANT_ID: process.env.PAYTR_MERCHANT_ID as string,
  PAYTR_MERCHANT_KEY: process.env.PAYTR_MERCHANT_KEY as string,
  PAYTR_MERCHANT_SALT: process.env.PAYTR_MERCHANT_SALT as string,
  TELEGRAM_BOT_TOKEN: process.env.TELEGRAM_BOT_TOKEN as string,
  TELEGRAM_CHAT_ID: process.env.TELEGRAM_CHAT_ID as string,
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID as string,
  GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET as string,
  NEXT_AUTH_SECRET: process.env.NEXT_AUTH_SECRET as string,
  NEXT_API_SECRET: process.env.NEXT_API_SECRET as string,
  NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL as string,
  PAYTR_API_URL: process.env.PAYTR_API_URL as string,
  GOOGLE_GA_TRACKING_ID: process.env.GOOGLE_GA_TRACKING_ID as string,
  GOOGLE_SITE_VERIFICATION_ID: process.env
    .YANDEX_SITE_VERIFICATION_ID as string,
  YANDEX_SITE_VERIFICATION_ID: process.env
    .YANDEX_SITE_VERIFICATION_ID as string,
  SENDGRID_API_KEY: process.env.SENDGRID_API_KEY as string,
  JAP_SECRET: process.env.JAP_SECRET as string,
  JAP_API: process.env.JAP_API as string,
  NODE_ENV: process.env.NODE_ENV,
}
